/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    CorporationFeeScheduleDto, DtoListResponseModel, DtoModelResponse,
    GeneralStatus,
    SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CorporationFeeSchedule<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags CorporationFeeSchedule
   * @name PostCorporationFeeSchedule
   * @request POST:/CorporationFeeSchedule/Add
   */
  postPost = (data: CorporationFeeScheduleDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<CorporationFeeScheduleDto>, any>({
      path: `/CorporationFeeSchedule/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CorporationFeeSchedule
   * @name LoadList
   * @request GET:/CorporationFeeSchedule/Load
   */
  loadList = (
    query?: {
      /** @format int32 */
      Take?: number;
      /** @format int32 */
      Skip?: number;
      IncludeIds?: number[];
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      /** @format int64 */
      PracticeId?: number;
      ForDropdown?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<DtoListResponseModel<CorporationFeeScheduleDto>, any>({
      path: `/CorporationFeeSchedule/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CorporationFeeSchedule
   * @name GetByIdList
   * @request GET:/CorporationFeeSchedule/GetById
   */
  getByIdList = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<DtoModelResponse<CorporationFeeScheduleDto>, any>({
      path: `/CorporationFeeSchedule/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CorporationFeeSchedule
   * @name DeleteDelete
   * @request DELETE:/CorporationFeeSchedule/Delete
   */
  deleteDelete = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<DtoModelResponse<CorporationFeeScheduleDto>, any>({
      path: `/CorporationFeeSchedule/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CorporationFeeSchedule
   * @name UpdateCreate
   * @request POST:/CorporationFeeSchedule/Update
   */
  updateCreate = (data: CorporationFeeScheduleDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<CorporationFeeScheduleDto>, any>({
      path: `/CorporationFeeSchedule/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
